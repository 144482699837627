import React from "react"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import PropTypes from "prop-types"
import { getPostObj } from "../utils/utils";

import Button from '@bit/azheng.joshua-tree.button';
import ButtonGroup from '@bit/azheng.joshua-tree.button-group';
import ButtonGroupMap from '../components/Button/ButtonGroupMap';
import Body from "./doctor/Body"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import TopSection from "./doctor/TopSection"

var classNames = require("classnames")

const SingleDoctor = ({ pageContext, location, data }) => {

  var post;
  var language;
  let postObj = getPostObj(pageContext, data.allDoctorsJson.nodes[0], data.allSpanishDoctorsJson.nodes[0]);
  post = postObj.post;
  language = postObj.language;

  const doctor = data.googleJson.data;

  var backUrl = "/" + data.backButton.nodes[0].title;

  var ctaClasses = classNames("column", {
    "is-2": post.hasImages,
    "is-4": !post.hasImages
  })

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO title={post.metaTitle} description={post.metaDescription} pathname={location.pathname} schemaPage={post.schemaPage ? post.schemaPage : null} />
        <div className="doctor-page joshua-tree-content">

          <TopSection language={language} post={post} backUrl={backUrl} doctor={doctor} />
          <Body language={language} post={post} />


          {/* Learn More CTA Section */}
          <div className="body-section color-back doctor-cta">
            <div className="columns has-text-centered">

              <div className="column">
                <h3 style={{ marginBottom: "1.125rem" }}>{post.learnMore.heading}</h3>
              </div>

            </div>
            <div className="columns has-text-centered">
              <div className={ctaClasses}></div>
              <div className="column mobile-col">
                <p>
                  {post.learnMore.blurb}
                </p>
              </div>
              <div className={ctaClasses}></div>
            </div>
            <div className="columns">
              <div className="column">
                <ButtonGroupMap isCentered buttons={post.learnMore.buttons} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
query doctorPages ($title: String!)  {
  googleJson {
    data {
      rating
      totalReviews
      code
      platform
      platformId
      url
    }
  }
  backButton: allUniquePagesJson(filter: {template: {eq: "our-oral-surgery-practice"}}) {
    nodes {
      title
    }
  }
  allDoctorsJson(filter: {title: {eq: $title}}) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        hasMainDrImage
        hasImages
        badge {
          hasBadge
          badgeImage
        }
        topBlurb
        cutoutImage
        aboutSection {
          body
          youtube
        }
        educationResidency {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          textOrPhotoFirst
        }
        hobbiesInterests {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          textOrPhotoFirst
        }
        certifications {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          textOrPhotoFirst
        }
        learnMore {
          heading
          blurb
          buttons {	
            button {	
              appearance	
              destination	
              buttonText	
              href	
            }	
          }
        }
      }
    }
    allSpanishDoctorsJson(filter: {title: {eq: $title}}) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        hasImages
        badge {
          hasBadge
          badgeImage
        }
        topBlurb
        cutoutImage
        aboutSection {
          body
          youtube
        }
        educationResidency {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        hobbiesInterests {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        certifications {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        learnMore {
          heading
          blurb
          buttons {
            button {
              appearance
              destination
              buttonText
              href
            }
          }
        }
      }
    }
  }
`

SingleDoctor.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.shape({
    allDoctorsJson: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          heading: PropTypes.string,
          metaTitle: PropTypes.string,
          metaDescription: PropTypes.string,
          schemaPage: PropTypes.string,
          hasImages: PropTypes.bool
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

export default SingleDoctor
